"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _pdf_link_service = require("pdfjs-dist/lib/web/pdf_link_service");

var _default = _pdf_link_service.PDFLinkService;
exports.default = _default;